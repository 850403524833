// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import Headline from '../../components/Headline';
import LoadingIndicator from '../../components/LoadingIndicator';
import Tile from '../../components/tiles/Tile';

import { itsUrlWithSize } from '../../utils/image';

import type PackageStore from '../../stores/PackageStore';
import type TypeStore from '../../stores/OfferOrPackageTypeStore';
import type Package from '../../models/Package';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumDateFromDateObject, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

type Props = {
  packageStore: PackageStore,
};

@inject('masterStore', 'packageStore', 'breadcrumbRouterStore')
@observer
export default class PageTravelMedia extends Component<Props> {
  static breadcrumb = 'Erinnerungsstücke';

  componentDidMount() {
    this.store.fetchList();
  }

  @computed get store(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('media');
  }

  badgeFor(pkg: Package) {
    if (!pkg.isBookable) return pkg.bookingStatus;
    if (pkg.prelimenary) return 'in Bearbeitung';
    if (pkg.isBooked) return 'Reserviert';
    return null;
  }

  triggered = false;

  render() {
    if (this.store.isLoading) return <LoadingIndicator />;

    const { headline, teaserText, imageUri } = this.store.meta;

    if (this.triggered === false && this.store.items) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = this.store.items.map((item: Package) => ({
        code: item.code,
        name: item.title,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(item.price)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="page">
        <Headline title={headline} />
        <div className="l-row l-mod-sub">
          <div className="l-col double">
            <p dangerouslySetInnerHTML={{ __html: teaserText }} />
          </div>
        </div>
        {!!imageUri && (
          <div className="l-row l-mod-sub">
            <div className="l-col double">
              <div className="l-block">
                <img src={imageUri} alt={headline} />
              </div>
            </div>
          </div>
        )}
        <div className="l-tiles">
          {this.store.items.map((pkg, index) => (
            <div
              key={pkg.typeId}
              onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
            >
              <Tile
                full
                isBooked={pkg.isBooked}
                disabled={!pkg.isBookable}
                link={`/reisemedien/${pkg.typeId}`}
                imageSrc={itsUrlWithSize(pkg.imageSrc, 272, 153)}
                smallImageSrc={itsUrlWithSize(pkg.imageSrc, 136, 136)}
                title={pkg.title}
                text={pkg.subline}
                badge={this.badgeFor(pkg)}
                lineClamp={2}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
